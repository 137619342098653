@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCardsMapVariant {
  padding: 0 0 96px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (--viewportLarge) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.listingCards {
  padding: 0 0 72px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0 24px;
  width: 100%;

  @media (min-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (--viewportMedium) {
    padding: 0 0 96px 0;
    grid-template-columns: repeat(1, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.listingCard {
  width: 100%;
}

.userCard {
  margin-bottom: 24px;
  width: 100%;

  @media (--viewportMedium) {
    display: none;
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: transparent;
}

.container {
  display: none;
  flex-direction: column;
  gap: 8px;
  margin: 24px auto 0;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;

  width: 100%;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
  }
}

.profileContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  width: 100%;

  @media (--viewportMedium) {
    width: 75%;
  }
}

.header {
  display: flex;
  align-items: center;
}

.profileImage {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #ddd;
  margin: auto 15px auto 0;
  min-width: 48px;
  min-height: 48px;
}

.nameLocation {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.name {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  display: flex;
  align-items: center;
}

.verifiedIcon {
  color: var(--marketplaceColor);
  margin-left: 5px;
  width: 24px;
  height: 24px;
}

.whiteVerifiedIcon {
  color: var(--colorWhite);
  fill: var(--colorWhite);
  margin-right: 5px;
  width: 24px;
  height: 24px;
} 

.location {
  font-size: 14px;
  color: #666;
  margin: 5px 0 0;
  display: flex;
  align-items: center;
}

.locationIcon {
  margin: 6px 8px auto 0;
  min-width: 16px;
}

.socialMedia {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 16px;

  @media (--viewportLarge) {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0;
    gap: 8px 16px;
  }

}

.socialItem {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 4px 8px;
  background-color: #fff5f5;
  border: 1px solid #f5dcdc;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  color: #333;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  text-decoration: none;
  cursor: pointer;
}

.instagramIcon {
  color: #e1306c;
  font-size: 20px;
}

.youtubeIcon {
  color: #ff0000;
  font-size: 20px;
}

.facebookIcon {
  color: #3b5998;
  font-size: 20px;
}

.whatsappIcon {
  color: #25d366;
  font-size: 20px;
}

.linkedinIcon {
  color: #0077b5;
  font-size: 20px;
}

.twitterIcon {
  color: #000;
  font-size: 20px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px 8px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.tag {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  background-color: #f5f7ff;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  color: #555;
  font-weight: 500;
  cursor: default;
  transition: box-shadow 0.2s ease;
}

.tagIcon {
  margin-right: 5px;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 8px;
  height: 100%;
  width: 100%;
  gap: 16px;

  @media (--viewportMedium) {
    margin-top: 0;
    width: 30%;
  }
}

.viewProfileButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #333;
  border: 1px solid #ddd;
  padding: 12px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;

  height: fit-content;
  width: 100%;
}

.viewProfileButton:hover {
  background-color: #f4f4f4;
  text-decoration: none;
}


.collabButton {
  background-color: #EB2930;
  color: #fff;
  border: none;
  padding: 12px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;

  height: fit-content;
  width: 100%;
}

.collabButton:hover {
  background-color: #e04747;
}

.verification {
  text-align: right;
  font-size: 12px;
  color: #888;
  margin-top: 8px;
}

.socialVerified {
  color: #00bfff;
  fill: #00bfff;
  width: 24px;
  height: 24px;
}

.greenVerified {
  color: #25d366;
  fill: #25d366;
  width: 24px;
  height: 24px;
}

.modal {
  position: fixed;
  bottom: 0;
  left: 0;
  top: auto;
  right: auto;

  height: 100%;

  @media (--viewportMedium) {
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.scrollLayer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;

  @media (--viewportMedium) {
    flex-grow: 1;
    display: flex;

    /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
    background-color: var(--colorWhite);

    /* Additional styles for the modal window, dimming the background and positioning the modal */
    min-height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    justify-content: center;
      align-items: flex-start;
      padding: 0;
      background-color: rgba(0, 0, 0, 0.4);
      background-image: none;
  }
}

.modalContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
  margin: auto 0 0;
  background-color: transparent;
  height: fit-content;
  width: 100%;
  position: relative;

  @media (--viewportMedium) {
    flex-grow: 1;

    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;

    border-radius: var(--borderRadius);
    border-bottom: none;

    min-height: 100vh;
    flex-basis: 576px;
    flex-grow: 0;
    width: 576px;
    min-height: auto;
    height: auto;

    margin-top: calc(12.5vh + 96px);
    margin-bottom: 12.5vh;
    margin-left: auto;
    margin-right: auto;
  }
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.modalContent {
  background-color: white;
  border-radius: 12px 12px 0 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: auto;
  padding: 16px;
  text-align: center;
  font-family: Arial, sans-serif;

  @media (--viewportMedium) {
    border-radius: 16px;
  }
}

.modalContent h2 {
  font-size: 18px;
  margin-bottom: 16px;
}

.modalContent input[type="text"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
}

.modalContent select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  background-color: white;
  appearance: none; /* Oculta la flecha nativa */
  -webkit-appearance: none; /* Safari */
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23000' d='M2 0L0 2h4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
}

.modalButtons {
  display: flex;
  flex-direction: row;
  gap: 24px;

  border-top: 1px solid #ddd;
  padding: 16px 0 0;
  width: 100%;
}

.modalContent button {
  background-color: var(--marketplaceColor);
  color: white;
  padding: 0;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
}

.modalContent button:hover {
  background-color: #333;
}

.modalContent .clearButton {
  background: none;
  color: black;
  border: none;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  box-shadow: none;
  border: 1px solid #333;
}

.modalContent .clearButton:hover {
  color: var(--colorWhite);
  text-decoration: underline;
}

.notFavourite {
  background: var(--colorBlack);
  color: var(--colorWhite);
}

.notFavourite:hover {
  background: var(--colorBlack);
  color: var(--colorWhite);
}

.professionalCreator {
  display: flex;
  align-items: center;
  
  font-size: 12px;
  padding: 4px 8px;
  background: var(--marketplaceColor);
  color: var(--colorWhite);
  border-radius: 8px;
  margin-left: 8px;
}